<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-primary">
          <i class="fa fa-plus"></i> Refresh
        </button>
      </div>

      <div class="col-auto">
        <button @click="$router.push({
          name:'pricings-create'
        })" class="btn btn-primary">
          <i class="fa fa-plus"></i> Add Pricing
        </button>
      </div>
    </div>
    <br>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
            <!-- <th v-for="date in availableDates" :key="date.value" class="p-3">
              {{ date.label }} ({{ date.value }})
            </th> -->
            <th></th>
            <th>Pack</th> 
            <th>FullName</th> 
            <th>Hours</th>
            <th>Price</th>
            <th>Total</th>
            <th>Time tart</th>
            <th>Time end</th>
            <th>Remarque  </th>
            <th></th>
          </tr> 
      </thead>
      <tbody>
          <tr v-for="pricing in pricings" :key="pricing.id">
            <th></th>
            <th>
              {{ pricing.pricingType }}
            </th>
            <th>
              {{ pricing.fullName }}
            </th>
            <th>
              {{ pricing.hours }}
            </th>
            <th>
              {{ pricing.price }} 
            </th>

            <th>
              {{ pricing.priceTotal }} 
            </th>
            <th>
              {{ pricing.startTime }} 
            </th>
            <th>
              {{ pricing.endTime }} 
            </th>
            <th>
              {{ pricing.remark }} 
            </th>
            <th>
              <button @click="$router.push(
                {
                  name: 'pricings-edit',
                  params: {
                    reference:pricing.reference
                }
                })" class="btn ">

                Editer
              </button>
            </th>
            <!-- <td v-for="date in availableDates" :key="date.value"
            class="p-3"
            :style="isReserved(date.value, hour) ? { background: '#78ff5d !important' } : {}"
            @click="handleSlotClick(date.value, hour)" >
                {{ hour }}
              <span v-if="showPricingDetails(date.value, hour)">
                {{ showPricingDetails(date.value, hour).fullName }}
              </span>
            </td> -->
          </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      availableDates: [],
      timeSlots: []
    };
  },
  async mounted() {
    await this.$store.dispatch("pricing/getAll");
    
  },
  computed: {
    ...mapGetters('pricing', {
      pricings: 'getPricings',
    }),
  },
  methods: {
    
    async refresh() {
      await this.$store.dispatch("pricing/getAll");
      await this.generateAvailableDates();
      await this.generateTimeSlots();
    }
  }
};
</script>

<style scoped>
.bg-success {
  background: #78ff5d !important;
}
</style>
