var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Refresh ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({
        name:'pricings-create'
      })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add Pricing ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.pricings),function(pricing){return _c('tr',{key:pricing.id},[_c('th'),_c('th',[_vm._v(" "+_vm._s(pricing.pricingType)+" ")]),_c('th',[_vm._v(" "+_vm._s(pricing.fullName)+" ")]),_c('th',[_vm._v(" "+_vm._s(pricing.hours)+" ")]),_c('th',[_vm._v(" "+_vm._s(pricing.price)+" ")]),_c('th',[_vm._v(" "+_vm._s(pricing.priceTotal)+" ")]),_c('th',[_vm._v(" "+_vm._s(pricing.startTime)+" ")]),_c('th',[_vm._v(" "+_vm._s(pricing.endTime)+" ")]),_c('th',[_vm._v(" "+_vm._s(pricing.remark)+" ")]),_c('th',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push(
              {
                name: 'pricings-edit',
                params: {
                  reference:pricing.reference
              }
              })}}},[_vm._v(" Editer ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Pack")]),_c('th',[_vm._v("FullName")]),_c('th',[_vm._v("Hours")]),_c('th',[_vm._v("Price")]),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("Time tart")]),_c('th',[_vm._v("Time end")]),_c('th',[_vm._v("Remarque ")]),_c('th')])])
}]

export { render, staticRenderFns }